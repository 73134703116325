import axios from '@/http/axios'
import {createCancelTokenHandler} from '../axios/utils'

const cancelTokenHandlerObject = {
  getEvents: createCancelTokenHandler('getEvents')
}

function makeQuery (page = 1, filters = {}, sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  const filterKeys = Object.keys(filters)
  if (filterKeys.length) {
    filterKeys.forEach((key) => {
      if (filters[key]) {
        queries.push(`${key}=${filters[key]}`)
      }
    })
  }

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getEvents (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `api/v1/events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getEvents'].handleRequestCancellation().token
  })
}

export function getEvent (id) {

  return axios({
    url: `api/v1/events/${id}`,
    method: 'get'
  })
}

export function insertEvent (payment) {

  return axios({
    url: 'api/v1/events',
    data: payment,
    method: 'post'
  })
}

export function seenEvent (id, subscriber_id) {

  return axios({
    url: `api/v1/events/${id}/subscribers/${subscriber_id}/seen`,
    method: 'post'
  })
}
