import axios from '@/http/axios'

export function loginUser (credentials) {
  if (!credentials.super_admin) return axios({
    url: 'api/v1/auth/login',
    data: {
      email: credentials.email,
      code: credentials.otpCode
    },
    method: 'post'
  })

  else return axios({
    url: 'api/v1/auth/login',
    data: {
      super_admin: credentials.super_admin,
      phone_number: credentials.phoneNumber,
      token: credentials.token
    },
    method: 'post'
  })

}

export function sendOTPCode (phoneNumber) {
  return axios({
    url: 'api/v1/auth/send-otp',
    data: {
      email: phoneNumber
    },
    method: 'post'
  })
}

export function resendOTPCode (phoneNumber, voice) {
  return axios({
    url: 'api/v1/auth/send-otp',
    data: {
      email: phoneNumber,
      voice
    },
    method: 'post'
  })
}

export function logoutUser () {
  return axios({
    url: 'api/v1/auth/logout',
    method: 'post'
  })
}

export function getCompanyInfo () {
  return axios({
    url: 'api/v1/company/info',
    method: 'get'
  })
}
