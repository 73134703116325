<template>
  <div class="u-input custom-number-input">
    <label class="u-input-box" :class="[{'is-not-valid': !number.isValid, 'disabled': disabled}, classes || {}]" :style="getStyles" @click="$emit('clicked')">
<!--      <span class="input-label" :class="{'is-focus': number.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span><div class="u-input-label-box">-->
      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': number.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <div v-if="disabled"
            class="disable-input-value disabled"
            :class="[{'text-primary has-link': Object.values(this.route).length > 0}, $vs.rtl ? 'dir-rtl' : 'dir-ltr']"
            @click="handleClickInput">
        {{ number.value }}
      </div>
      <input v-else
             type="text"
             :class="[{'disabled': disabled, 'text-primary': Object.values(this.route).length > 0}, $vs.rtl ? 'dir-rtl' : 'dir-ltr']"
             @input="handleInputValue"
             @contextmenu="handleContextMenuClicked($event)"
             @click="handleClickInput"
             :autocomplete="false"
             :name="Math.random()"
             inputmode="numeric"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="number.value">
    </label>
    <span class="u-input-errors" v-if="!value.isValid && this.value.value.length > 0" >
      {{ invalidText || this.$t('customNumberInput.invalidText') }}
    </span>
  </div>
</template>

<script>
import {addComma} from '@/assets/js/functions'
import {customParseFloat} from "../../assets/js/functions";

export default {
  name: 'customNumberInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    invalidText: {
      type: String,
      default: () => { return '' }
    },
    hasComma: {
      type: Boolean,
      default: false
    },
    route: {
      type: Object,
      default () {
        return {}
      }
    },
    value: {
      type: Object,
      default () {
        return {
          value: '',
          isValid: true
        }
      }
    },
    maxLength: {
        type: Number,
        default: () => { return 0 }
    },
    classes: {
      type: [Object, Array, String],
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    min: {
      type: Number,
      default () {
        return -1
      }
    },
    max: {
      type: Number,
      default () {
        return -1
      }
    }
  },
  data () {
    return {
      number: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      // if (!styles.width) {
      //   styles.width = '230px'
      // }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.number.isValid = true
    }
  },
  methods: {
    handleInputValue () {
      if (this.hasComma) this.addComma()
      else {
        let sign = ''
        if (this.number.value.startsWith('-')) {
          sign = '-'
        }
        console.log(sign)
        this.number.value = `${sign}${this.number.value.toString().replace(/\D/g, '')}`
      }
      this.validateValue()
      this.$emit('input', {value: this.number.value.toString().replaceAll(',', ''), isValid: this.number.isValid})
    },
    validateValue () {
      if (this.min !== -1 && this.max !== -1) {
        this.number.isValid = customParseFloat(this.number.value || '0') >= this.min && customParseFloat(this.number.value || '0') <= this.max
      } else if (this.isEmpty) {
        this.number.isValid = true
      } else {
        this.number.isValid = this.number.value.toString().length > 0
      }
    },
    addComma () {
      this.number.value = addComma(this.number.value)
    },
    initValues () {
      let sign = ''
      const value = this.value.value.toString() || ''
      if (value.startsWith('-')) {
        sign = '-'
      }
      this.number.value = this.hasComma ? addComma(this.value.value) : `${sign}${this.value.value.toString().replace(/\D/g, '')}`
      this.validateValue()
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.number.value,
          paste: true
        },
        event: event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleClickInput () {
      if (Object.values(this.route).length > 0) {
        window.open(this.$router.resolve(this.route).href, '_blank')
      } else {
        this.$emit('clicked')
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    },

    'number.value': {
        handler (val, prev) {
            if (this.maxLength > 0 && val.length >= this.maxLength) {
                this.number.value = prev
                this.$emit('input', {value: this.number.value.toString().replaceAll(',', ''), isValid: this.number.isValid})
            }
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-number-input {
  position: relative;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border-radius: .5rem;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
    }

    input,
    .disable-input-value {
      width: 100%;
      min-height: 21px;
      background: transparent;
      border: none;
      outline: none !important;

      &.dir-rtl {
        direction: rtl;
      }

      &.dir-ltr {
        direction: ltr;
      }

      &.disabled {
        opacity: 0.75;
      }
    }
  }

  .disable-input-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .invalid-text-helper {
    font-size: 11px;
    color: #b00000;
  }
}
</style>
