<template>
  <v-lazy-image :src="src"
                :alt="alt"
                :src-placeholder="getStaticFileUrl('/images/logo.png')"/>
</template>

<script>
import vLazyImage from 'v-lazy-image'
import {getStaticFileUrl} from "../../assets/js/functions";

export default {
  name: 'lazyImage',
  components: {vLazyImage},
  props: {
    src: '',
    alt: ''
  },
  methods: {
    getStaticFileUrl (file) {
      return getStaticFileUrl(file)
    }
  }
}
</script>

<style scoped>
img {
  overflow: hidden;
  object-fit: cover;
}

.v-lazy-image {
  filter: blur(5px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
