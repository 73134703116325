<template>
  <div class="crud-tree"
       :class="{'ml-0': first ,'ml-big': !first && tree.options && tree.options.selectable}"
       v-if="Object.keys(tree).length > 0">

    <div class="crud-tree-parent relative cursor-pointer">

      <vs-icon v-if="tree.children && tree.children.length"
               class="collapse-icon"
               :class="show ? 'rotate-down-icon' : 'rotate-left-icon'"
               :icon="$vs.rtl ? 'icon-chevron-left' : 'icon-chevron-right'"
               icon-pack="feather"
               @click="show = !show"/>

      <vs-checkbox v-if="tree.options && tree.options.selectable && checkLimit()"
                   class="checkbox"
                   :class="checkboxClass"
                   v-model="selectedRows"
                   @change="selectAllChildren($event)"
                   :vs-value="tree.parent.id"/>

      <div class="parent-title inline-block"
           @click="tree.children.length === 0 ? $emit('row:clicked', tree.parent) : '', show = !show"
           :class="[parentTitleClass, hideOptions ? 'w-full' : '', !checkLimit() ? 'disabled' : '']">

        <span class="title">
        {{ tree.parent.i18n ? $t(tree.parent.i18n) : tree.parent.title }}

          <vs-icon v-if="tree.parent.hasOwnProperty('lock') && tree.parent.lock > 0"
                   :class="{'text-primary': tree.parent.lock === 1 ,'text-danger': tree.parent.lock === 2}"
                   icon="icon-lock"
                   icon-pack="feather"/>
        </span>
        <span class="details"
              :class="[tree.parent.balance < 0 ? 'text-success' : 'text-danger']"
              v-if="options && (options.type === 'documentsType' || options.type === 'openingBalanceType')">
          {{ addComma(tree.parent.balance || 0) + (tree.parent.balance ? ` ${ $locale.currency()}` : '') }}
        </span>
      </div>

      <div class="parent-options inline-block"
           v-if="!hideOptions">
        <!-- insert option -->
        <div class="parent-option">
          <vs-icon
            :class="tree.options && tree.options.hasOwnProperty('insertEnable') && !tree.options.insertEnable ? 'disabled' : ''"
            class="text-success"
            icon="icon-plus"
            icon-pack="feather"
            @click="tree.options && tree.options.hasOwnProperty('insertEnable') && !tree.options.insertEnable ? '' : insertPromptStatus = true"/>
<!--          <span class="tooltip" v-if="tree.options && tree.options.hasOwnProperty('insertEnable') && !tree.options.insertEnable">این عملیات غیر فعال است</span>-->

          <!-- insert prompt -->

          <vs-prompt
            class="p-0"
            color="danger"
            :buttons-hidden="true"
            title=""
            :active.sync="insertPromptStatus">

            <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
              <vs-row>
                <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                  <div @click="handleClick('saveBTN')">
                    <custom-icon icon="SAVE" color="success"/>
                  </div>
                </vs-col>

                <vs-spacer/>

                <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                  {{ options && options.type === 'accessList' ? $t('setting.accessGroup.insert.title') : '' }}
                  {{ options && options.type === 'documentsType' ? $t('accountancy.types.insert.title') : '' }}
                </vs-col>

                <vs-spacer/>

                <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                  <div @click="insertPromptStatus = false">
                    <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                  </div>
                </vs-col>
              </vs-row>
            </div>

            <div class="prompt-content py-2 px-3">
              <insert-access-group v-if="options && options.type === 'accessList'"
                                   :parent-id="tree.parent.id"
                                   :limit="getAccessGroup()"
                                   @insert="insertPromptStatus = false"/>

            </div>

          </vs-prompt>

          <!-- /insert prompt -->
        </div>
        <!-- /insert option -->

        <!-- update option -->
        <div class="parent-option">
          <vs-icon
            :class="tree.options && tree.options.hasOwnProperty('updateEnable') && !tree.options.updateEnable ? 'disabled' : ''"
            class="text-warning"
            icon="icon-edit-2"
            icon-pack="feather"
            @click="tree.options && tree.options.hasOwnProperty('updateEnable') && !tree.options.updateEnable ? '' : updatePromptStatus = true"/>


<!--          <span class="tooltip" v-if="tree.options && tree.options.hasOwnProperty('updateEnable') && !tree.options.updateEnable">این عملیات غیر فعال است</span>-->
          <!-- update prompt -->

          <vs-prompt
            class="p-0"
            color="danger"
            :buttons-hidden="true"
            title="ویرایش"
            :active.sync="updatePromptStatus">

            <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
              <vs-row>
                <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                  <div
                    @click="handleClick('saveBTN')">
                    <custom-icon icon="SAVE" color="success"/>
                  </div>
                </vs-col>

                <vs-spacer/>

                <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                  {{ options && options.type === 'accessList' ? $t('setting.accessGroup.edit.title') : '' }}
                  {{ options && options.type === 'documentsType' ? $t('accountancy.types.edit.title') : '' }}
                </vs-col>

                <vs-spacer/>

                <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                  <div @click="updatePromptStatus = false">
                    <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                  </div>
                </vs-col>
              </vs-row>
            </div>

            <div class="prompt-content py-2 px-3">
              <insert-access-group v-if="options && options.type === 'accessList'"
                                   :parent-id="tree.parent.id"
                                   :limit="limit"
                                   :access-group="getAccessGroup()"
                                   @update="updatePromptStatus = false"/>
            </div>

          </vs-prompt>

          <!-- /update prompt -->
        </div>
        <!-- /update option -->

        <!-- delete option -->
        <div class="parent-option">
          <vs-icon
            :class="tree.options && tree.options.hasOwnProperty('deleteEnable') && !tree.options.deleteEnable ? 'disabled' : ''"
            class="text-danger"
            icon="icon-trash"
            icon-pack="feather"
            @click="tree.options && tree.options.hasOwnProperty('deleteEnable') && !tree.options.deleteEnable ? '' : $refs[`delete-${tree.parent.id}`].showDialog()"/>
<!--          <span class="tooltip" v-if="tree.options.hasOwnProperty('deleteEnable') && !tree.options.deleteEnable">این عملیات غیر فعال است</span>-->

          <!-- delete prompt -->

          <custom-dialog v-if="options && options.type === 'accessList'"
                         :ref="`delete-${tree.parent.id}`"
                         :title="$t('setting.accessGroup.confirmations.delete.title')"
                         :body="$t('setting.accessGroup.confirmations.delete.body', {name: tree.parent.title})"
                         @accept="deleteRole"/>

          <custom-dialog v-if="options && options.type === 'documentsType'"
                         :ref="`delete-${tree.parent.id}`"
                         :title="$t('accountancy.types.confirmations.delete.title')"
                         :body="$t('accountancy.types.confirmations.delete.body', {name: tree.parent.title})"
                         @accept="deleteAccount"/>

          <!-- /delete prompt -->
        </div>
        <!-- /delete option -->
      </div>
    </div>

<!--    <transition-group :css="false"
                      @before-enter="animation ? beforeEnterRow : ''"
                      @enter="animation ? enterRow : ''"
                      @leave="animation ? leaveRow : ''">-->

      <crud-tree v-for="(child, child_index) in tree.children"
                 :key="`child-${child_index}`"
                 :data-index="child_index"
                 v-show="show"
                 v-model="selectedRows"
                 :first-hide="child.options && child.options.hasOwnProperty('firstHide') ? child.options.firstHide : false"
                 :tree="child"
                 :select-one="selectOne"
                 :hide-options="checkHideOptions(child)"
                 :options="options"
                 :limit="options.type === 'insertAccessList' ? limit : options.type === 'accessList' ? getAccessGroup() : []"
                 @child:selected="$emit('child:selected', $event)"
                 @row:clicked="$emit('row:clicked', $event)"
                 @insert="$emit('insert', $event)"
                 @update="$emit('update', $event)"
                 @delete="$emit('delete', $event)"/>
<!--    </transition-group>-->
  </div>
</template>

<script>
import gsap from 'gsap'
import InsertAccessGroup from '@/views/admin/settings/access/insert/insertAccessGroup'
import {deleteRole} from '@/http/requests/roles'
import CustomDialog from '@/components/customDialog/customDialog'
import {addComma} from '@/assets/js/functions'
import CustomIcon from '../customIcon/customIcon'
import {deleteAccount} from '../../http/requests/accountancy'

export default {
  name: 'crudTree',
  components: {
    CustomIcon,
    CustomDialog,
    InsertAccessGroup
  },
  model: {
    prop: 'selected',
    event: 'child:selected'
  },
  props: {
    tree: {},
    options: {},
    hideOptions: {
      type: Boolean,
      default: false
    },
    selected: {},
    firstHide: {
      type: Boolean,
      default: false
    },
    selectOne: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    },
    limit: {}
  },
  data () {
    return {
      show: true,
      selectedRows: [],
      rowClickedPromptStatus: false,
      // selectAllChildren: false,
      insertPromptStatus: false,
      updatePromptStatus: false,
      deletePromptStatus: false
    }
  },
  computed: {
    parentTitleClass () {
      const selectable = this.tree.options && this.tree.options.hasOwnProperty('selectable') && this.tree.options.selectable
      const hasChildren = this.tree.children && this.tree.children.length

      return selectable && hasChildren && this.checkLimit() ? 'p-big' : selectable && this.checkLimit() ? 'p-medium' : hasChildren ? 'p-small' : ''
    },
    checkboxClass () {
      const hasChildren = this.tree.children && this.tree.children.length
      return hasChildren ? 'p-small' : ''
    }
  },
  created () {
    this.selectedRows = this.selected ? this.selected : []
    this.show = !this.firstHide
  },
  methods: {
    addComma (price) {
      return addComma(price.toString().replaceAll('-', ''))
    },
    checkHideOptions (tree) {
      let counter = 0
      if (tree.options) {
        if (!tree.options.hasOwnProperty('insertEnable') || (tree.options.hasOwnProperty('insertEnable') && !tree.options.insertEnable)) counter++

        if (!tree.options.hasOwnProperty('updateEnable') || (tree.options.hasOwnProperty('updateEnable') && !tree.options.updateEnable)) counter++

        if (!tree.options.hasOwnProperty('deleteEnable') || (tree.options.hasOwnProperty('deleteEnable') && !tree.options.deleteEnable)) counter++
      }
      return counter === 3
    },
    checkLimit () {
      if (this.limit && this.limit.length > 0) {
        // console.log(this.tree.parent.id)
        return this.limit.indexOf(this.tree.parent.id) >= 0 || this.childrenLimited()
      } else {
        return true
      }
    },
    getAllChildren (tree = this.tree) {
      const all = []
      tree.children.forEach((child) => {
        if (all.indexOf(child.parent.id) === -1) all.push(child.parent.id)

        this.getAllChildren(child).forEach((grandChild) => {
          if (all.indexOf(grandChild) === -1) all.push(grandChild)
        })
      })
      return all
    },
    selectAllChildren (value) {
      if (this.selectedRows.indexOf(this.tree.parent.id) > -1) {
        this.getAllChildren().forEach((child) => {
          if (this.selectedRows.indexOf(child) === -1) {
            this.selectedRows.push(child)
          }
        })
      } else {
        this.getAllChildren().forEach((child) => {
          if (this.selectedRows.indexOf(child) > -1) {
            this.selectedRows.splice(this.selectedRows.indexOf(child), 1)
          }
        })
      }
      this.updateData(value)
    },
    childrenSelected () {
      let counter = 0
      this.getAllChildren().forEach((child) => {
        if (this.selectedRows.indexOf(child) >= 0) {
          counter++
        }
      })
      return this.tree.children.length > 0 && counter === this.getAllChildren().length
      // const found = this.getAllChildren().some(r=> this.selectedRows.indexOf(r) >= 0)
      // return found
    },
    childrenLimited () {
      let counter = 0
      this.getAllChildren().forEach((child) => {
        if (this.limit.indexOf(child) >= 0) {
          counter++
        }
      })
      return this.tree.children.length > 0 && counter === this.getAllChildren().length
      // const found = this.getAllChildren().some(r=> this.selectedRows.indexOf(r) >= 0)
      // return found
    },
    refreshRowClickedPrompt () {
      this.rowClickedPromptStatus = false
      setTimeout(() => {
        this.rowClickedPromptStatus = true
      }, 100)
    },
    getRowClickedInsertButtonId () {
      switch (this.tree.parent.page) {
      case 'banks':
        return 'insertBankBTN'

      case 'cashBoxes':
        return 'insertCashBoxBTN'
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    updateData (value) {
      if (this.selectOne) {
        if (value.length > 1) {
          value = [value[value.length - 1]]
          this.selectedRows = value
        }
        this.$emit('child:selected', value)
      } else {
        this.$emit('child:selected', this.selectedRows)
      }
    },
    getAccessGroup () {
      const permissions = []
      this.tree.parent.permissions.forEach((permission) => {
        permissions.push(permission.id)
      })
      return {
        id: this.tree.parent.id,
        name: this.tree.parent.title,
        allowedActiveSessions: this.tree.parent.allowed_active_sessions,
        access: permissions
      }
    },
    deleteAccount () {
      deleteAccount(this.tree.parent.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('accountancy.types.notifications.delete.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$emit('delete')
        this.$store.dispatch('helper/changeAccount')
      })
        .catch(() => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.types.notifications.delete.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
    },
    deleteRole () {
      if (this.options && this.options.type === 'accessList') {
        deleteRole(this.tree.parent.id).then(() => {
          this.$store.dispatch('helper/changeRole')

          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('setting.accessGroup.notifications.delete.success'),
            time: 2400,
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather'
          })
        }).catch(error => {
          switch (error.response.status) {
          case 404:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('setting.accessGroup.notifications.delete.error'),
              time: 2400,
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break

          case 409:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('setting.accessGroup.notifications.delete.cantDelete'),
              time: 2400,
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break
          }
        })
      }
    },
    beforeEnterRow (el) {
      el.style.opacity = 0
      el.style.transform = 'translate(30px, 0px)'
    },
    enterRow (el, done) {
      gsap.to(el, {
        opacity: 1,
        x: '0',
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    },
    leaveRow (el, done) {
      gsap.to(el, {
        opacity: 0,
        x: '30px',
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    }
  },
  watch: {
    '$store.state.helper.openingBalanceChanged': {
      handler (val) {
        if (val) {
          this.rowClickedPromptStatus = false
        }
      },
      deep: true
    },
    'selected': {
      handler (val) {
        this.selectedRows = val
      },
      deep: true
    },
    'selectedRows': {
      handler (val) {
        if (this.tree.parent && val.indexOf(this.tree.parent.id) > -1 && this.tree.children.length > 0) {
          const selected = this.childrenSelected()

          if (!selected) {
            this.selectedRows.splice(val.indexOf(this.tree.parent.id), 1)
          }
        } else if (this.tree.parent && val.indexOf(this.tree.parent.id) === -1) {
          const selected = this.childrenSelected()

          if (selected) {
            this.selectedRows.push(this.tree.parent.id)
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.crud-tree {
  margin: 5px 0 0 20px;
  line-height: 30px;
  overflow-x: hidden;

  &.ml-big {
    margin-left: 45px !important;
  }

  .crud-tree-parent {
    background: #222222;
    padding: 0 5px;
    border-radius: 4px;
    height: 30px;

    .p-small {
      padding-left: 20px;
    }

    .p-medium {
      padding-left: 25px;
    }

    .p-big {
      padding-left: 45px;
    }

    i {

      &.collapse-icon {
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        z-index: 10;

        &.rotate-down-icon:before {
          display: inline-block;
          transform: rotate(90deg);
          transition: all .2s ease;
        }

        &.rotate-left-icon:before {
          display: inline-block;
          transform: rotate(0deg);
          transition: all .2s ease;
        }
      }
    }

    .checkbox {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;

      input {
        width: 20px !important;
      }
    }

    .parent-title {
      width: calc(100% - 110px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      float: left;
      font-size: 15px;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.disabled {
        opacity: 0.5;
      }

      &.w-full {

        .title {
          display: inline-block;
          min-width: 50% !important;
        }

        .details {
          width: 50% !important;
        }
      }

      .title {
        display: inline-block;
        min-width: 70%;
      }

      .details {
        display: inline-block;
        width: 30%;
        text-align: right;
        padding-right: 5px;
      }
    }

    .parent-options {
      width: 110px;
      height: 100%;
      float: right;

      .parent-option {
        height: 100%;
        position: relative;
        display: inline-block;
        width: calc(110px / 3);
        text-align: center;
        border-right: 1px solid #131314;
        padding: 0 5px;

        &:first-child {
          border-left: 1px solid #131314;
        }

        &:last-child {
          border: none;
          padding: 0 5px 0 10px;
        }

        &:hover {
          .tooltip {
            visibility: visible;
          }
        }

        .disabled {
          opacity: 0.5;
          cursor: default;
        }

        .tooltip {
          visibility: hidden;
          position: absolute;
          width: 200px;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          z-index: 1;
          color: #888888;
          background: #333333;
          border: 1px solid #888888;
          border-radius: 3px;
          cursor: default;

          &::after {
            content: " ";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #888888 transparent transparent;
          }
        }

        i {
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
